import React from 'react'
import axios from 'axios'
import {InventoryModal} from '../models/InventoryModal'
import {IInventoryFormValues} from '../containers/CreateInventoryForm'
import {IImportInventoryFormValues} from '../containers/ImportInventoryForm'
import {Toaster} from '../../../modules/comman/components/Toaster'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_INVENTORY_URL = `${API_URL}api/v1/auth/admin/stock/`
export const LINK_FILE = `${API_URL}api/v1/auth/admin/stock/link/file/`
export const GET_INVENTORY_URL_STOCK_DETAIL = `${API_URL}api/v1/stock/`
export const GET_TWIN_INVENTORY_URL = `${API_URL}v1/auth/twin-stock/`
export const GET_MATRIX_INVENTORY_URL = `${API_URL}v1/auth/matrix-stock/`
export const GET_SINGLE_INVENTORY_URL = `${API_URL}v1/auth/stock/`
export const SEARCH_INVENTORY_URL = `${API_URL}api/v1/auth/admin/stock/filter/`
export const CREATE_INVENTORY_URL = `${API_URL}api/v1/auth/admin/stock/bulk`
export const EDIT_INVENTORY_URL = `${API_URL}v1/auth/inventory/`
export const DELETE_INVENTORY_URL = `${API_URL}v1/auth/inventory/`
export const CHANGE_STATUS_INVENTORY_URL = `${API_URL}v1/auth/inventory/active-status/`
export const EXPORT_ALL_STOCK = `${API_URL}api/v1/auth/both/stocks-export`
export const EXPORT_SELECTED_STOCK = `${API_URL}v1/auth/all/select/stock/`
export const GET_ROLE_URL = `${API_URL}v1/auth/role/`
export const GET_INVENTORY_TRAIL_URL = `${API_URL}api/v1/auth/admin/stock/trail/`
export const GET_DOWNLOAD_LABEL_URL = `${API_URL}api/v1/auth/admin/stock/generate-label`
export const DELETE_ALL_STOCKS_URL = `${API_URL}api/v1/auth/admin/stocks`
export const DELETE_SINGLE_STOCKS_URL = `${API_URL}api/v1/auth/admin/stock`
export const EDIT_SINGLE_STOCKS_URL = `${API_URL}api/v1/auth/admin/stock`
export const GET_INVENTORY_VIEW_MEMO_DATA_URL = `${API_URL}api/v1/auth/admin/stock-memos`
export const UPDATE_RAP_URL = `${API_URL}api/v1/stock/update-rap`
export const CREATE_STONE_URL = `${API_URL}api/v1/auth/admin/stock`
export const BULK_UPLOAD_STOCK_DISCOUNT = `${API_URL}api/v1/auth/admin/stock/bulk-update`

export const GET_LOCATION_URL = `${API_URL}api/v1/auth/admin/locations/`

// Get Inventory Data
export async function getInventoryData(startIndex: number, limitNumber: number, type: string) {
  try {
    let inventoryData = await axios.get(
      `${GET_INVENTORY_URL}${startIndex}/${limitNumber}?type=${type ? type : ''}`
    )
    return inventoryData
  } catch (e) {
    throw e
  }
}

// Get All Role Data for DropDown
export async function getRoleDataForDropDown() {
  try {
    let roleData = await axios.get(`${GET_ROLE_URL}`)
    return roleData
  } catch (e) {
    throw e
  }
}

// Get Twin Inventory Data
export async function getTwinInventoryData(startIndex: number, limitNumber: number) {
  try {
    let twinInventoryData = await axios.get(`${GET_TWIN_INVENTORY_URL}${startIndex}/${limitNumber}`)
    return twinInventoryData
  } catch (e) {
    throw e
  }
}

// Get Matrix Inventory Data
export async function getMatrixInventoryData(filterObject: any) {
  try {
    let matrixInventoryData = await axios.post(`${GET_MATRIX_INVENTORY_URL}`, {
      filterObject,
    })
    return matrixInventoryData
  } catch (e) {
    throw e
  }
}

// Get Single Inventory Data
export async function getSingleInventoryData(Id: number) {
  try {
    let singleInventoryData = await axios.get(`${GET_INVENTORY_URL}${Id}`)
    return singleInventoryData
  } catch (e) {
    throw e
  }
}

// Get Single Inventory Data
export async function getSingleInventoryDataForStockDetail(Id: number) {
  try {
    let singleInventoryData = await axios.get(`${GET_INVENTORY_URL_STOCK_DETAIL}${Id}`)
    return singleInventoryData
  } catch (e) {
    throw e
  }
}

export async function linkVideoFileNameToStock(name: string, stockId: string) {
  try {
    let linkedVideoData = await axios.post(`${LINK_FILE}`, {
      stockId: stockId,
      fileName: name,
      type: 'video',
    })
    return linkedVideoData
  } catch (e) {
    throw e
  }
}

export async function linkImageFileNameToStock(name: string, stockId: string) {
  try {
    let linkedImageData = await axios.post(`${LINK_FILE}`, {
      stockId: stockId,
      fileName: name,
      type: 'image',
    })
    return linkedImageData
  } catch (e) {
    throw e
  }
}

// Search Inventory Data
export async function getSearchInventoryData(
  startIndex: number,
  limitNumber: number,
  filterObject: any,
  type: string,
  searchText: any,
  location?: any
) {
  try {
    let inventoryData = await axios.post(
      `${SEARCH_INVENTORY_URL}${startIndex}/${limitNumber}?type=${type ? type : ''}`,
      {
        filterObject,
        searchText,
        location: location ? location : null
      }
    )
    return inventoryData
  } catch (e) {
    throw e
  }
}

// Create Inventory Data
export async function createInventoryData(inventoryObject: IInventoryFormValues) {
  try {
    let createInventoryData = await axios.post(`${CREATE_INVENTORY_URL}`, {
      ...inventoryObject,
    })
    return createInventoryData
  } catch (e) {
    throw e
  }
}

// Create Inventory Data
export async function importInventoryData(
  inventoryObject: IImportInventoryFormValues,
  type: string
) {
  try {
    let createInventoryData = await axios.post(`${CREATE_INVENTORY_URL}`, {
      ...inventoryObject,
      file_name: inventoryObject.fileName,
      type: type,
    })
    return createInventoryData
  } catch (e) {
    throw e
  }
}

// Delete Inventory Data
export async function deleteInventoryData(inventoryId: string) {
  try {
    let deleteInventoryData = await axios.delete(`${DELETE_INVENTORY_URL}${inventoryId}`)
    return deleteInventoryData
  } catch (e) {
    throw e
  }
}

// Change Status Inventory Data
export async function changeStatusInventoryData(inventoryId: string, flag: boolean) {
  try {
    let inventoryData = await axios.put(`${CHANGE_STATUS_INVENTORY_URL}`, {
      inventoryId,
      flag,
    })
    return inventoryData
  } catch (e) {
    throw e
  }
}

// Edit Inventory Data
export async function editInventoryData(
  inventoryObject: IInventoryFormValues,
  inventoryId: string
) {
  try {
    let editInventoryData = await axios.put(`${EDIT_INVENTORY_URL}`, {
      ...inventoryObject,
      inventoryId,
    })
    return editInventoryData
  } catch (e) {
    throw e
  }
}

// export selected stock list
export async function exportSelectedStockList(stockIds: IInventoryFormValues) {
  try {
    let exportSelectedStockData = await axios.post(`${EXPORT_SELECTED_STOCK}`, {
      stockIds,
    })
    return exportSelectedStockData
  } catch (e) {
    throw e
  }
}

// export All Stock List
export async function exportAllStockList(type: string,filterObject?: any,searchText?: any,
  location?: any) {
  try {
    let exportAllStockData = await axios.post(`${EXPORT_ALL_STOCK}?type=${type}`,
    {
      filterObject: filterObject ? filterObject : null,
      searchText,
        location: location ? location : null
    })
    return exportAllStockData
  } catch (e) {
    throw e
  }
}

// Get Inventory Trail Data
export async function getInventoryTrailData(Id: number) {
  try {
    let trailInventoryData = await axios.get(`${GET_INVENTORY_TRAIL_URL}${Id}`)
    return trailInventoryData
  } catch (e) {
    throw e
  }
}

export async function getDownloadLabelUrl(inventoryDetails: any) {
  try {
    let tempArray: any = []
    console.log(inventoryDetails)
    inventoryDetails.map((item: any) => {
      if (item && item._id) {
        tempArray.push(item._id)
      }
    })
    console.log(tempArray)
    let getDownloadLabelUrlLink = await axios.post(`${GET_DOWNLOAD_LABEL_URL}`, {
      stocks: tempArray,
    })
    return getDownloadLabelUrlLink
  } catch (e) {
    Toaster({
      type: 'error',
      title: 'INVENTORY',
      description: 'Failed To Download Label',
    })
    throw e
  }
}
// Delete Inventory Data
export async function deleteAllStockData() {
  try {
    let deleteInventoryData = await axios.delete(`${DELETE_ALL_STOCKS_URL}`)
    return deleteInventoryData
    // console.log('deleted successfully')
  } catch (e) {
    throw e
  }
}

// Delete Inventory Data
export async function deleteSingleStockData(id: string) {
  try {
    let deleteInventoryData = await axios.delete(
      `${DELETE_SINGLE_STOCKS_URL}?stock_id=${id ? id : ''}`
    )
    return deleteInventoryData
    console.log('deleted')
  } catch (e) {
    throw e
  }
}

export async function editSingleStockData(inventoryObject: any, inventoryId: string) {
  try {
    let editInventoryData = await axios.put(`${EDIT_SINGLE_STOCKS_URL}`, {
      ...inventoryObject,
      stock_id: inventoryId,
    })
    return editInventoryData
  } catch (e) {
    throw e
  }
}

export async function getInventoryMemoData(
  startIndex: number,
  limitNumber: number,
  stock_id: string
) {
  try {
    let inventoryData = await axios.get(
      `${GET_INVENTORY_VIEW_MEMO_DATA_URL}?stock_id=${stock_id ? stock_id : ''}`
    )
    return inventoryData
  } catch (e) {
    throw e
  }
}

export async function updateRapData() {
  try {
    let updateRapData = await axios.get(`${UPDATE_RAP_URL}`)
    return updateRapData
    // console.log('deleted successfully')
  } catch (e) {
    throw e
  }
}

export async function createStoneDataApi(stoneObject: any) {
  try {
    let createStoneData = await axios.post(`${CREATE_STONE_URL}`, {
      ...stoneObject,
    })
    return createStoneData
  } catch (e) {
    throw e
  }
}

export async function bulkUploadDiscountData(file_name: any) {
  try {
    let bulkUploadDiscount = await axios.put(`${BULK_UPLOAD_STOCK_DISCOUNT}`, {
      file_name : file_name,
    })
    return bulkUploadDiscount
  } catch (e) {
    throw e
  }
}

export async function getLocationForDropdownApi(startIndex: number, limitNumber: number) {
  try {
    let locationData = await axios.post(`${GET_LOCATION_URL}${startIndex}/${limitNumber}`, {
      searchText: '',
    })
    return locationData
  } catch (e) {
    throw e
  }
}